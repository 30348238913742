export const TOP_NAV_LINKS = [
  {
    link: '/',
    text: 'Home',
  },
  {
    link: '/about',
    text: 'About',
  },
  {
    link: '/projects',
    text: 'Projects',
  },
  {
    link: '/contact',
    text: 'Contact',
  },
]
